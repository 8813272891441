import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  version: "1.0",
  viewBox: "0 0 400 400"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M184.7 29c-35.9 6.5-63.4 32.4-71.8 67.5-1.8 7.8-2 11-1.6 23.5.5 16.1 1.9 22.1 8 35.4 10 21.5 29.3 38.7 52.2 46.3 7.5 2.5 9.1 2.7 27 2.7 20.5.1 25-.7 38-7.1 12.7-6.3 30.2-21.4 34.5-29.9.8-1.6 2.5-4.5 3.7-6.4 3.5-5.6 8.1-18.1 9.9-27.1 2.6-13 1.6-31.5-2.5-43.9-9.6-29.5-31.4-50.5-61.4-59.1-8.1-2.3-28-3.4-36-1.9zm28.9 39.1c24.9 4.4 47.7 20.2 62.2 43.3l3.1 4.8-2.4 4.7c-7.1 13.9-22.6 28.4-39 36.6-14.1 7.1-20.1 8.6-35.6 9.2-19 .8-33.4-2.9-49.4-12.7-13.1-8-26.2-21.7-32.4-33.8l-2.1-4.1 6.2-9.1c13.1-19.5 33.8-33.5 56.3-38.3 8.3-1.8 24.8-2.1 33.1-.6z" }, null, -1),
    _createElementVNode("path", { d: "M188.3 70.5c-16.9 3.7-30.9 17-35.8 33.9-1.3 4.5-1.6 8.3-1.3 15.2.4 8 1 10.3 4.1 16.8 6.3 13.3 16.8 22.3 29.9 25.7 28.6 7.5 55.9-9.7 59.6-37.6 2.2-16.2-1.7-29-12-40.1-6.8-7.2-15.1-11.9-24.5-13.9-8.2-1.8-11.9-1.8-20 0zM67.5 215.9c-30 3.1-57.1 26.8-64.2 56.1-2.4 10.2-2.4 30.7 0 40.8C8.4 334 22.8 351.9 43 362c19.7 9.9 52.2 10 71.8.1 9.2-4.6 24.2-16.8 24.2-19.6 0-.5-4.7-5.7-10.4-11.4l-10.4-10.3-5.7 4.6c-21.1 17.1-45.8 17.4-62.6.7-22.1-22.2-15.9-63.5 11.3-75.5 15.8-7 34.3-4.1 48.8 7.6 3.3 2.6 6.4 4.8 6.9 4.8s2.6-2.1 4.7-4.7c2.2-2.6 6.7-7.9 10.1-11.8 3.5-3.8 6.3-7.5 6.3-8.1 0-1.2-5.8-6.1-12.5-10.6-5.9-4-16.2-8.5-23-10.2-7.3-1.8-25.5-2.7-35-1.7zm257.1 1.2c-7.4 1.8-18 7.7-23.3 12.9-3 3-6.6 7.9-8.5 11.8-3.1 6.2-3.3 7.5-3.6 18.2-.5 14.4 1.4 20.2 9.1 28.7 6.4 6.9 15.6 11.5 33.7 16.8 23.9 6.9 26 7.6 30.2 10.8 7.8 6 7.4 14-.8 19.7-4.9 3.3-18.7 4-28.3 1.5-8.1-2.1-18.8-7.4-25.4-12.7-4.8-3.7-5.2-3.9-6.4-2.1-.8 1-2.8 3.4-4.7 5.3-1.8 1.9-5.6 6.5-8.5 10.3-6 7.6-6 7.6 1.6 12.9 13.1 9 22.6 13.1 36.5 15.8 17.7 3.5 38.6 2 50.6-3.7 3-1.4 8.2-5.2 11.7-8.4 4.9-4.6 6.9-7.4 8.9-12.4 2.3-5.9 2.6-7.8 2.6-19.9 0-15.1-1.1-19.3-7.1-26-7.3-8.3-18-13.5-41.9-20.3-23-6.4-28.1-9.6-28.3-17.7-.1-4.3 2.6-8.4 7.4-10.9 5.2-2.8 20.2-1.9 28.9 1.7 7.7 3.2 13.6 6.2 16.9 8.8 3.3 2.5 4.4 2.3 6.6-1.5 1-1.7 4.8-7.2 8.3-12.1 4.9-6.8 6.2-9.3 5.5-10.5-1.8-2.9-16.7-10.9-26.3-14.2-9-3.1-10.4-3.3-25.5-3.5-8.8-.2-17.8.1-19.9.7zM150 292.6v74.6l45.3-.5c47.8-.4 48.5-.5 59.7-5.5 15.5-6.9 24.1-21.5 22.7-38.7-1.1-13.8-7.2-23.3-19.3-30.1l-6.3-3.5 4.8-4.2c9.7-8.4 12-13.9 12.1-28.3 0-9.3-.2-10.6-3.1-16.2-3.4-6.9-10.8-14-18.1-17.2-10.8-4.7-13.8-5-57-5H150v74.6zm79.1-41.8c2.4 1.4 4.3 3.7 5.3 6.2 2.1 5.2 2 6.5-.4 11.5-3.5 7.3-11.9 9.4-36.7 9.5H183v-30.1l21.3.3c20 .3 21.4.4 24.8 2.6zm6.1 57.9c5.9 2.7 8.8 7.1 8.8 13.5 0 5.6-3.3 10.1-9.5 12.9-3.5 1.6-6.9 1.9-27.7 1.9H183v-31.2l23.8.4c21.9.3 24.1.5 28.4 2.5z" }, null, -1)
  ])))
}
export default { render: render }