<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 2000 2000"
  >
    <g
      :fill="color"
      :fill-opacity="opacity"
    >
      <path
        d="m1437.64,999.44c-241.01,0-437.08-196.07-437.08-437.08s196.07-437.08,437.08-437.08,437.08,196.07,437.08,437.08-196.07,437.08-437.08,437.08Zm0-824.78c-213.78,0-387.7,173.92-387.7,387.7s173.92,387.7,387.7,387.7,387.7-173.92,387.7-387.7-173.92-387.7-387.7-387.7Z"
      />
      <path
        d="m1234.49,587.06c-13.64,0-24.69-11.05-24.69-24.69,0-125.63,102.21-227.85,227.84-227.85,13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69c-98.4,0-178.46,80.06-178.46,178.47,0,13.64-11.05,24.69-24.69,24.69Z"
      />
      <path
        d="m1437.64,1874.71c-82.69,0-149.96-67.27-149.96-149.95v-479.71c0-7.61,3.51-14.79,9.51-19.47l125.27-97.64c8.93-6.96,21.43-6.96,30.36,0l125.26,97.64c6,4.68,9.51,11.86,9.51,19.47v479.71c0,82.68-67.27,149.95-149.95,149.95Zm-100.58-617.6v467.65c0,55.46,45.12,100.57,100.58,100.57s100.57-45.12,100.57-100.57v-467.65l-100.57-78.39-100.58,78.39Z"
      />
      <path
        d="m1437.64,1172.11c-13.64,0-24.69-11.05-24.69-24.69v-172.66c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v172.66c0,13.64-11.05,24.69-24.69,24.69Z"
      />
      <path
        d="m727.09,555.58H149.98c-13.64,0-24.69-11.05-24.69-24.69V149.98c0-13.64,11.05-24.69,24.69-24.69h577.11c13.64,0,24.69,11.05,24.69,24.69v380.92c0,13.64-11.05,24.69-24.69,24.69Zm-552.42-49.38h527.73V174.67H174.67v331.54Z"
      />
      <path
        d="m727.09,1215.15H149.98c-13.64,0-24.69-11.05-24.69-24.69v-380.91c0-13.64,11.05-24.69,24.69-24.69h577.11c13.64,0,24.69,11.05,24.69,24.69v380.91c0,13.64-11.05,24.69-24.69,24.69Zm-552.42-49.38h527.73v-331.53H174.67v331.53Z"
      />
      <path
        d="m727.09,1874.71H149.98c-13.64,0-24.69-11.05-24.69-24.69v-380.92c0-13.64,11.05-24.69,24.69-24.69h577.11c13.64,0,24.69,11.05,24.69,24.69v380.92c0,13.64-11.05,24.69-24.69,24.69Zm-552.42-49.38h527.73v-331.54H174.67v331.54Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#4d4d4d'
    },
    opacity: {
      type: Number,
      default: 1
    }
  }
}
</script>
