import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 34 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#042B5A",
      d: "M33.71 19.876h-.062a26.99 26.99 0 0 0-1.259-2.672h.411c.325.625.628 1.258.91 1.903v.77zM.29 4.114a26.68 26.68 0 0 1 5.057-1.172l.49-2.558h3.97l-.89 2.322a26.25 26.25 0 0 1 3.195.187L13.35.384h4.362l-2.078 3.17a26.703 26.703 0 0 1 7.774 3.417c.477.307.94.63 1.389.965h-.63a26.647 26.647 0 0 0-8.744-4.053l-2.541 3.96h-1.824v2.698l-4.5 7.375h-1.44v2.466L.506 27.616H.359L11.944 3.24a25.515 25.515 0 0 0-3.155-.17L.322 27.615H.289V4.114zm0 23.048L5.265 3.328A26.337 26.337 0 0 0 .31 4.525H.289v22.637z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#042B5A",
      d: "M14.31 14.12h2.454v-2.295H14.31V10.73h2.986l-.163-2.3h-5.32v8.424h2.497V14.12zm10.378 2.71h2.684l1.223-2.067 1.254 2.069h2.746l-2.648-4.365 2.385-4.023h-2.72l-1.025 1.747-1.063-1.753h-2.766l2.456 4.049-2.526 4.342zm-3.122.092a4.31 4.31 0 0 0 4.367-4.24 4.381 4.381 0 0 0-4.555-4.198 4.381 4.381 0 0 0-4.193 4.199 4.306 4.306 0 0 0 4.373 4.239h.008zm-.772-6.088a.78.78 0 0 1 .745-.773.805.805 0 0 1 .788.773v3.575a.768.768 0 0 1-1.533 0v-3.575zm-5.915 12.309h2.54v-1.686h-2.54v-1.146h3.095v-1.824h-5.055v7.877h5.093v-1.895h-3.133v-1.326zm10.932-4.656-.974 4.509-.951-4.509h-1.681l-.905 4.555-.944-4.555h-2.004l1.819 7.877h1.827l.895-4.515.922 4.514h1.79l1.826-7.876h-1.62zm6.635 3.559c-.522-.482-1.12-.61-1.792-.8-.794-.224-1.232-.249-1.232-.711a.623.623 0 0 1 .68-.561h.005a1.03 1.03 0 0 1 1.085.79l1.716-.573a2.637 2.637 0 0 0-2.665-1.957c-1.792 0-2.888 1.102-2.888 2.586 0 1.283.745 1.914 2.238 2.36.892.27 1.365.249 1.365.823 0 .302-.28.54-.745.54a1.165 1.165 0 0 1-1.28-.962l-1.882.414c.32 1.494 1.433 2.382 3.138 2.382a2.986 2.986 0 0 0 2.238-.923c.43-.474.666-1.092.66-1.734a2.193 2.193 0 0 0-.644-1.677h.003v.003zm-21.97.394-2.343-3.918H5.859v7.847h1.996v-4.585l2.676 4.574h1.893v-7.846h-1.942l-.005 3.928z"
    }, null, -1)
  ])))
}
export default { render: render }