import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 34 34"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#042B5A",
      d: "M17.004.6A16.363 16.363 0 0 0 .6 17c0 9.08 7.307 16.4 16.403 16.4C26.099 33.4 33.4 26.08 33.4 17 33.4 7.92 26.099.6 17.004.6z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M7.663 12.57a4.46 4.46 0 0 0-4.47 4.47 4.46 4.46 0 0 0 4.47 4.471c1.523 0 2.119-1.074 2.119-1.074v.977h2.352v-4.373a4.46 4.46 0 0 0-4.471-4.47zm0 2.338c1.183 0 2.13.952 2.13 2.133 0 1.18-.947 2.133-2.13 2.133a2.126 2.126 0 0 1-2.13-2.133c0-1.18.947-2.133 2.13-2.133zm5.039-5.416v7.549a4.462 4.462 0 0 0 4.472 4.472 4.458 4.458 0 0 0 4.467-4.472 4.458 4.458 0 0 0-4.467-4.472c-1.523 0-2.12 1.077-2.12 1.077V9.492h-2.352zm4.472 5.416c1.183 0 2.131.952 2.131 2.133 0 1.18-.948 2.133-2.13 2.133a2.127 2.127 0 0 1-2.132-2.133c0-1.18.949-2.133 2.131-2.133zm9.322-2.338a4.46 4.46 0 0 0-4.47 4.47 4.46 4.46 0 0 0 4.47 4.471 4.46 4.46 0 0 0 4.363-3.485h-2.473a2.127 2.127 0 0 1-1.89 1.148 2.126 2.126 0 0 1-2.13-2.133c0-1.18.947-2.133 2.13-2.133a2.13 2.13 0 0 1 1.896 1.153h2.467c-.444-2-2.22-3.49-4.363-3.49z"
    }, null, -1)
  ])))
}
export default { render: render }