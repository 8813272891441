<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 2000 2000"
  >
    <g
      :fill="color"
      :fill-opacity="opacity"
    >
      <g>
        <path
          d="m1617.81,1852.92H382.19c-67.92,0-123.18-55.25-123.18-123.18V270.26c0-67.92,55.26-123.18,123.18-123.18h975.71c6.55,0,12.83,2.6,17.46,7.23l358.41,358.41c4.63,4.63,7.23,10.91,7.23,17.46v1199.55c0,67.93-55.26,123.18-123.18,123.18ZM382.19,196.46c-40.69,0-73.8,33.1-73.8,73.8v1459.48c0,40.69,33.1,73.8,73.8,73.8h1235.63c40.69,0,73.8-33.11,73.8-73.8V540.41l-343.94-343.95H382.19Z"
        />
        <path
          d="m1716.3,555.29h-358.83c-13.64,0-24.69-11.05-24.69-24.69V171.77c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v334.14h334.14c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
        />
      </g>
      <g>
        <path
          d="m1453.35,764.53h-906.7c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h906.7c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
        />
        <path
          d="m1110.42,555.29h-220.84c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h220.84c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
        />
        <path
          d="m1453.35,973.77h-906.7c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h906.7c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
        />
        <path
          d="m1453.35,1183h-906.7c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h906.7c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
        />
      </g>
      <g>
        <path
          d="m708.12,1503.47h-161.47c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h161.47c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
        />
        <path
          d="m1453.35,1503.47h-409.21c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h409.21c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#4d4d4d'
    },
    opacity: {
      type: Number,
      default: 1
    }
  }
}
</script>
