<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 2000 2000"
  >
    <g
      :fill="color"
      :fill-opacity="opacity"
    >
      <path
        d="m1586.2,431.85H413.8c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h1172.41c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
      />
      <path
        d="m1385.22,658.52h-355.53c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h355.53c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
      />
      <path
        d="m1385.22,867.76h-355.53c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h355.53c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
      />
      <path
        d="m776.39,1124.09h-362.59c-13.64,0-24.69-11.05-24.69-24.69v-465.56c0-13.64,11.05-24.69,24.69-24.69h362.59c13.64,0,24.69,11.05,24.69,24.69v465.56c0,13.64-11.05,24.69-24.69,24.69Zm-337.9-49.38h313.21v-416.18h-313.21v416.18Z"
      />
      <path
        d="m883.49,1386.62H293.83c-80.92,0-146.75-65.82-146.75-146.74V285.11c0-80.92,65.83-146.75,146.75-146.75h1412.35c80.92,0,146.74,65.83,146.74,146.75v756.61c0,13.64-11.05,24.69-24.69,24.69s-24.69-11.05-24.69-24.69V285.11c0-53.69-43.68-97.37-97.36-97.37H293.83c-53.69,0-97.37,43.68-97.37,97.37v954.77c0,53.68,43.68,97.36,97.37,97.36h589.67c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
      />
      <path
        d="m1439.71,1861.64c-227.84,0-413.2-185.37-413.2-413.21s185.36-413.2,413.2-413.2,413.21,185.36,413.21,413.2-185.37,413.21-413.21,413.21Zm0-777.03c-200.61,0-363.82,163.2-363.82,363.82s163.2,363.83,363.82,363.83,363.83-163.21,363.83-363.83-163.21-363.82-363.83-363.82Z"
      />
      <path
        d="m1439.71,1613.25h0c-8.07,0-15.63-3.94-20.25-10.57l-148.21-212.57c-7.8-11.19-5.05-26.57,6.13-34.37,11.19-7.79,26.57-5.05,34.37,6.13l127.95,183.52,127.96-183.52c7.8-11.2,23.19-13.94,34.37-6.13s13.94,23.19,6.13,34.37l-148.22,212.57c-4.62,6.63-12.18,10.57-20.25,10.57Z"
      />
      <g>
        <path
          d="m348.26,1670.55c-23.23,0-42.13-18.89-42.13-42.13s18.9-42.12,42.13-42.12,42.12,18.89,42.12,42.12-18.9,42.13-42.12,42.13Zm0-49.38c-4,0-7.26,3.25-7.26,7.25s3.26,7.26,7.26,7.26,7.25-3.26,7.25-7.26-3.26-7.25-7.25-7.25Z"
        />
        <path
          d="m589.72,1670.55c-23.23,0-42.13-18.89-42.13-42.13s18.9-42.12,42.13-42.12,42.12,18.89,42.12,42.12-18.9,42.13-42.12,42.13Zm0-49.38c-4,0-7.26,3.25-7.26,7.25s3.26,7.26,7.26,7.26,7.25-3.26,7.25-7.26-3.26-7.25-7.25-7.25Z"
        />
        <path
          d="m831.18,1670.55c-23.23,0-42.13-18.89-42.13-42.13s18.9-42.12,42.13-42.12,42.13,18.89,42.13,42.12-18.9,42.13-42.13,42.13Zm0-49.38c-4,0-7.25,3.25-7.25,7.25s3.26,7.26,7.25,7.26,7.25-3.26,7.25-7.26-3.26-7.25-7.25-7.25Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#4d4d4d'
    },
    opacity: {
      type: Number,
      default: 1
    }
  }
}
</script>
