<template>
  <!-- BEGIN: hero section -->
  <v-container fluid class="px-0 py-10 bg-primaryContainerLight">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" lg="6" class="d-flex justify-center align-center" :style="{ lineHeight: 'normal' }">
          <span class="text-h3 text-center text-onPrimaryContainerLight pb-6">
            See who will pay the most for your used&nbsp;car
          </span>
        </v-col>

        <!-- BEGIN: valuator tool -->
        <v-col id="form-start" cols="12" sm="10" lg="6">
          <v-card variant="flat" width="100%" class="rounded-xl py-4 bg-surfaceLight" ref="widget">
            <v-card-text>
              <v-row align="center" justify="center" no-gutter>
                <v-col cols="12">
                  <p class="text-h4 font-weight-medium text-center text-onSurfaceLight">Start your quote</p>
                </v-col>
                <v-col cols="12" class="my-4 pb-0">
                  <p class="text-subtitle-1 font-weight-medium text-center text-onSurfaceLight">Select your vehicle lookup
                    method</p>
                </v-col>
                <v-col cols="10" class="pa-0 rounded-xl bg-surfaceContainerLight">
                  <v-item-group v-model="selectedWorkflowIndex" class="d-flex flex-column justify-center">
                    <template v-for="(wf, idx) in workflows" :key="idx">
                      <v-item #default="{ selectedClass, select }" selected-class="">
                        <v-btn class="d-flex flex-column h-auto rounded-pill text-capitalize"
                          :class="isCurrentWorkflow(idx) ? 'font-weight-bold text-onSecondaryContainerLight bg-secondaryContainerLight' : 'text-onSurfaceContainerLight bg-surfaceContainerLight'"
                          :variant="isCurrentWorkflow(idx) ? 'tonal' : 'text'" elevation="0"
                          :style="{ letterSpacing: 'inherit' }" :ripple="false" @click="select">
                          <v-container class="fill-height">
                            <v-row justify="center" align="center" style="flex-wrap: nowrap">
                              <v-col cols="auto" class="py-4 text-onSurfaceLight">
                                {{ wf.title }}
                              </v-col>
                              <v-col cols="auto" class="py-0 pr-4 pl-0 d-flex align-center">
                                <IconsCheckCircle v-if="isCurrentWorkflow(idx)" :width="24" :height="24"
                                  icon-color="rgb(var(--v-theme-secondary10))" icon-opacity="0.8" />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-btn>
                      </v-item>
                    </template>
                  </v-item-group>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <UiShowError :auto-close="true" />
                  <component v-if="selectedWorkflow" :is="selectedWorkflow.component" :vehicle-model="vehicleModel"
                    @select-vehicle="startQuote" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- END: valuator tool -->

      </v-row>
    </v-container>
  </v-container>
  <!-- END: hero section -->

  <!-- BEGIN: as seen on section -->
  <v-container fluid class="py-8 bg-surfaceLight">
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <p class="text-h5 text-sm-h4 font-weight-medium">As seen on</p>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="auto" v-for="(logo, index) in [abcLogo, nbcLogo, foxLogo, cbsLogo]" :key="index" class="mx-auto mx-sm-6">
        <component :is="logo" width="" height="60" />
      </v-col>
    </v-row>
  </v-container>
  <!-- END: as seen on section -->

  <!-- BEGIN: small display l+r content section -->
  <v-container fluid class="d-lg-none">
    <v-container class="py-4 text-left">
      <v-row justify="center" align="center" class="d-lg-none">
        <v-col cols="12" sm="9" md="8" class="rounded-xl">
          <CarOffersSvg class="rounded-xl" />
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="9">
          <p class="text-h5 text-sm-h4 text-center">
            What is OnlineCarValues.com?
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="9">
          <p class="text-body-1">
            There are thousands of buyers wanting to give you cash for your used car, right now! But how do you know some
            slick-haired car salesman won't take you to the cleaners? Or that a private party buyer will even show up? We
            streamline the selling process by filtering through tons of real, cash offers, making it easy for you to see
            who will pay the most for your car.
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="9" class="pb-0">
          <p class="text-h5">
            We collect the highest offers
          </p>
        </v-col>
        <v-col cols="12" sm="11" md="9">
          <p class="text-body-1">
            We skim and retrieve offers from sources across the web, both local and national, all ready to buy your car.
            You can be sure that you're getting the highest prices and the most accurate appraisals of your vehicle.
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="9" class="pb-0">
          <p class="text-h5">
            Easy, convenient, and accommodating process
          </p>
        </v-col>
        <v-col cols="12" sm="11" md="9">
          <p class="text-body-1">
            When it comes to appraisal, too many buyers require you to input each and every minuscule detail about your
            car, down to the tiniest paint chip. Some even require you to bring it in for inspection before giving a
            value. We've vowed to keep it simple by cutting out the filler questions without compromising the accuracy of
            the appraisal.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  <!-- END: small display l+r content section -->

  <!-- BEGIN: large display l+r content section -->
  <v-container class="d-none d-lg-block mt-8 pb-12 text-left">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <p class="text-h4 text-center">
          What is OnlineCarValues.com?
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="9">
        <p class="text-body-1">
          There are thousands of buyers wanting to give you cash for your used car, right now! But how do you know some
          slick-haired car salesman won't take you to the cleaners? Or that a private party buyer will even show up? We
          streamline the selling process by filtering through tons of real, cash offers, making it easy for you to see who
          will pay the most for your car.
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="6" class="d-none d-lg-flex align-start justify-center justify-md-end">
        <CarOffersSvg class="rounded-xl" />
      </v-col>
      <v-col cols="6">
        <v-row justify="center" align="center">
          <v-col cols="12" class="pb-0">
            <p class="text-h5">
              We collect the highest offers
            </p>
          </v-col>
          <v-col cols="12">
            <p class="text-body-1">
              We skim and retrieve offers from sources across the web, both local and national, all ready to buy your car.
              You can be sure that you're getting the highest prices and the most accurate appraisals of your vehicle.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="12" class="pb-0">
            <p class="text-h5">
              Easy, convenient, and accommodating process
            </p>
          </v-col>
          <v-col cols="12">
            <p class="text-body-1">
              When it comes to appraisal, too many buyers require you to input each and every minuscule detail about your
              car, down to the tiniest paint chip. Some even require you to bring it in for inspection before giving a
              value. We've vowed to keep it simple by cutting out the filler questions without compromising the accuracy
              of the appraisal.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <!-- END: large display l+r content section -->

  <!-- BEGIN: three card content section -->
  <v-container fluid class="bg-surfaceVariantLight text-onSurfaceVariantLight">
    <v-container class="py-8">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="9" class="text-center">
          <p class="text-h4">
            Should you sell to a local dealer or a national company?
          </p>
        </v-col>
        <v-col cols="12" sm="11" md="9">
          <p class="text-body-1">
            A common question that people ask when looking to sell their used vehicle is whether it's better to sell to a
            local dealership or to a large national corporation. We've broken it down to help you understand which
            solution is best for you when it comes time to sell.
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="rounded-xl elevation-0 h-100 bg-surfaceContainerLowestLight">
            <v-card-text class="text-center text-md-left">
              <v-row justify="center" align="center" class="py-8">
                <v-col cols="11" md="10">
                  <v-avatar size="64" color="primaryContainerLight" class="pa-3">
                    <IconsTouch />
                  </v-avatar>
                </v-col>
                <v-col cols="11" md="10" class="pb-2">
                  <p class="text-h6">
                    Convenience
                  </p>
                </v-col>
                <v-col cols="11" md="10">
                  <p class="text-body-1 text-left">
                    An online-only process can sometimes mean more hoops to jump through during the appraisal and
                    transaction. Local dealerships can be more flexible throughout the sales process, but often require
                    speaking or meeting with a live person (BONUS if you prefer dealing with real people!).
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8" md="4">
          <v-card class="rounded-xl elevation-0 h-100 bg-surfaceContainerLowestLight">
            <v-card-text class="text-center text-md-left">
              <v-row justify="center" align="center" class="py-8">
                <v-col cols="11" md="10">
                  <v-avatar size="64" color="primaryContainerLight" class="pa-3">
                    <IconsCarCoins />
                  </v-avatar>
                </v-col>
                <v-col cols="11" md="10" class="pb-2">
                  <p class="text-h6">
                    Quick Sale
                  </p>
                </v-col>
                <v-col cols="11" md="10">
                  <p class="text-body-1 text-left">
                    When selling to a local dealership, you will most often receive payment immediately after handing over
                    the keys, since they are able to process the transaction on-site. This means you can get cash quickly
                    and easily, making it a convenient option, especially if you require urgent funds.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8" md="4">
          <v-card class="rounded-xl elevation-0 h-100 bg-surfaceContainerLowestLight">
            <v-card-text class="text-center text-md-left">
              <v-row justify="center" align="center" class="py-8">
                <v-col cols="11" md="10">
                  <v-avatar size="64" color="primaryContainerLight" class="pa-3">
                    <IconsMagnifyingGlass />
                  </v-avatar>
                </v-col>
                <v-col cols="11" md="10" class="pb-2">
                  <p class="text-h6">
                    Expert Appraisal
                  </p>
                </v-col>
                <v-col cols="11" md="10">
                  <p class="text-body-1 text-left">
                    Depending on where you live, selling to a national company may be a better option if there are only a
                    few dealerships nearby to provide on-site appraisals. Overall, local dealerships are able to work with
                    you a bit more on price rather than a take-it or leave-it approach from a national brand.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  <!-- END: three card content section -->

  <!-- BEGIN: side-by-side content section -->
  <v-container fluid>
    <v-container class="py-8">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="6" class="order-last order-md-first">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="9">
              <p class="text-h4 text-center text-md-left">
                Not just another vehicle appraisal site...
              </p>
            </v-col>
            <v-col cols="12" sm="11" md="9">
              <p class="text-body-1">
                Other vehicle evaluator sites are complicated, irritating, and seem to have endless questions before ever
                giving you a clear indication of what your car is worth. We've taken a different approach by streamlining
                the process of getting vehicle values, without bogging you down with filler questions and other details.
              </p>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" md="9">
              <UiNavButton @click="goTo('#form-start', { duration: 300, offset: -60, easing: 'easeInOutCubic' })">
                Get started
              </UiNavButton>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          <OfferComparisonSvg class="rounded-xl" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  <!-- END: side-by-side content section -->

  <!-- BEGIN: three card content section -->
  <v-container fluid class="bg-primaryFixedLight text-onPrimaryFixedLight">
    <v-container class="py-8">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="9">
          <p class="text-h4 text-center">
            Three major factors that determine the price of a used&nbsp;car
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="rounded-xl elevation-0 h-100 bg-primaryFixedDimLight">
            <v-card-text>
              <v-row justify="center" align="center" class="py-6">
                <v-col cols="11" md="10" class="text-center text-md-left pb-2">
                  <p class="text-h6">
                    Condition
                  </p>
                </v-col>
                <v-col cols="11" md="10">
                  <p class="text-body-1">
                    The better the condition of the vehicle, the higher the price it can command. Factors that influence
                    the condition of a vehicle include its age, mileage, service history, accident history, and overall
                    wear and tear.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8" md="4">
          <v-card class="rounded-xl elevation-0 h-100 bg-primaryFixedDimLight">
            <v-card-text>
              <v-row justify="center" align="center" class="py-6">
                <v-col cols="11" md="10" class="text-center text-md-left pb-2">
                  <p class="text-h6">
                    Quick Sale
                  </p>
                </v-col>
                <v-col cols="11" md="10">
                  <p class="text-body-1 text-left">
                    When selling to a local dealership, you will most often receive payment immediately after handing over
                    the keys, since they are able to process the transaction on-site. This means you can get cash quickly
                    and easily, making it a convenient option, especially if you require urgent funds.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8" md="4">
          <v-card class="rounded-xl elevation-0 h-100 bg-primaryFixedDimLight">
            <v-card-text>
              <v-row justify="center" align="center" class="py-6">
                <v-col cols="11" md="10" class="text-center text-md-left pb-2">
                  <p class="text-h6">
                    Expert Appraisal
                  </p>
                </v-col>
                <v-col cols="11" md="10">
                  <p class="text-body-1 text-left">
                    Depending on where you live, selling to a national company may be a better option if there are only a
                    few dealerships nearby to provide on-site appraisals. Overall, local dealerships are able to work with
                    you a bit more on price rather than a take-it or leave-it approach from a national brand.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  <!-- END: three card content section -->

  <!-- BEGIN: side-by-side content section -->
  <v-container fluid>
    <v-container class="py-8">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="11" md="9">
          <p class="text-h4 text-center">
            What should you bring when selling your&nbsp;car?
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="11" sm="6" class="align-self-start">
          <KeyHandoffSvg class="rounded-xl" />
        </v-col>
        <v-col cols="12" sm="10" md="6">
          <v-row justify="center" align="center" class="text-center text-md-left mb-4" no-gutters>
            <v-col cols="11">
              <v-card class="rounded-xl elevation-0 bg-transparent">
                <v-card-text>
                  <v-row justify="center" align="center" no-gutters>
                    <v-col cols="11" class="mb-3">
                      <v-avatar size="64" color="primaryContainerLight" class="pa-3">
                        <IconsDriversLicense />
                      </v-avatar>
                    </v-col>
                    <v-col cols="11" class="pb-2">
                      <p class="text-h6">
                        Valid driver's license
                      </p>
                    </v-col>
                    <v-col cols="11">
                      <p class="text-body-1 text-left">
                        Acceptable forms of identification include a valid driver's license, a state-issued identification
                        card for non-drivers, or a passport/passport card.
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="11">
              <v-card class="rounded-xl elevation-0 bg-transparent">
                <v-card-text>
                  <v-row justify="center" align="center" no-gutters>
                    <v-col cols="11" class="mb-3">
                      <v-avatar size="64" color="primaryContainerLight" class="pa-3">
                        <IconsPaperTitle />
                      </v-avatar>
                    </v-col>
                    <v-col cols="11" class="pb-2">
                      <p class="text-h6">
                        Title &amp; payoff information
                      </p>
                    </v-col>
                    <v-col cols="11">
                      <p class="text-body-1 text-left">
                        It is necessary for all title holders to make arrangements to attend. Additionally, in cases where
                        there is a lien that has already been paid and is mentioned on the title, you will require a
                        release document from the lien holder.
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="11">
              <v-card class="rounded-xl elevation-0 bg-transparent">
                <v-card-text>
                  <v-row justify="center" align="center" no-gutters>
                    <v-col cols="11" class="mb-3">
                      <v-avatar size="64" color="primaryContainerLight" class="pa-3">
                        <IconsKeyHand />
                      </v-avatar>
                    </v-col>
                    <v-col cols="11" class="pb-2">
                      <p class="text-h6">
                        Keys, remotes, and owner's manual
                      </p>
                    </v-col>
                    <v-col cols="11">
                      <p class="text-body-1 text-left">
                        Missing keys, remotes, or owner's manuals can potentially reduce any offer value for your vehicle.
                        It's important to keep these items together and ready for the sale.
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  <!-- END: side-by-side content section -->

  <!-- BEGIN: cta card section -->
  <v-container fluid class="pb-12 gradient-separator-footer">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="10" md="8">
        <v-card variant="flat" color="inverseSurfaceLight" width="100%" class="rounded-xl py-4">
          <v-card-text class="text-inverseOnSurfaceLight">
            <v-row justify="center" align="center" class="py-8">
              <v-col cols="10">
                <v-row>
                  <v-col cols="12">
                    <p class="text-h4 text-center">
                      Ready to see your offers?
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p class="text-body-1 text-left">
                      We'll gather real offers from buyers across the web, both local and national, all ready to buy your
                      car. Select the offer that you like best and we'll get you connected to complete the sale.
                      <!-- Looking for hassle-free way to get the most cash for your car? We've got you covered! Our process starts by simply entering your vehicle's information. We'll gather offers from buyers across the web, both local and national, all ready to buy your car. Select the offer that you like best and we'll get you connected to complete the sale. -->
                    </p>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="12" sm="11" md="9">
                    <UiNavButton color="inversePrimaryLight" text-color="text-inverseOnPrimaryLight"
                      @click="goTo('#form-start', { duration: 300, offset: -60, easing: 'easeInOutCubic' })">
                      Get started
                    </UiNavButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- END: cta card section -->
</template>

<script setup lang="ts">
import { useElementSize } from "@vueuse/core";
import { useGoTo } from "vuetify";

// @ts-ignore
import CarOffersSvg from "~/assets/svg/car_offers.svg?component";
// @ts-ignore
import OfferComparisonSvg from "~/assets/svg/offer_comparison.svg?component";
// @ts-ignore
import KeyHandoffSvg from "~/assets/svg/key_handoff.svg?component";
// @ts-ignore
import abcLogo from "~/assets/svg/abc_logo.svg?component";
// @ts-ignore
import nbcLogo from "~/assets/svg/nbc_logo.svg?component";
// @ts-ignore
import foxLogo from "~/assets/svg/fox_logo.svg?component";
// @ts-ignore
import cbsLogo from "~/assets/svg/cbs_logo.svg?component";

const { buildGtmEvent, pushEvent } = useGtmEvent();
const logger = useLogger("index.vue");
const vehicleModel = useVehicleModel();
const goTo = useGoTo();

// TODO: get height of widget to transition smoothly when changing workflows
const widget = ref(null);
const { height } = useElementSize(widget);

definePageMeta({
  middleware: ["reset-quote"],
});

const { workflows, selectedWorkflowIndex, selectedWorkflow } = useWorkflow();

const startQuote = () => {
  // TODO: should alternative workflow field values be cleared here before navigating to quote? (e.g. clear plate fields if vin workflow was used)

  pushEvent(`${vehicleModel.workflowName.value}WorkflowStartQuote`, {
    category: "workflow",
    action: "submit",
    label: "NavButton",
  }, buildGtmEvent({ vehicleModel }));

  navigateTo({ path: "/quote" });
};

const isCurrentWorkflow = (currentIndex: number): boolean => {
  return selectedWorkflowIndex.value === currentIndex;
};

watchEffect(async () => {
  if (selectedWorkflow.value?.name) {
    logger.debug("Setting vehicle store workflow name");
    vehicleModel.workflowName.value = selectedWorkflow.value.name;
  }
});
</script>

<style lang="scss" scoped>
// [v-cloak] {
//   display: none;
// }

.gradient-separator-hero {
  background: linear-gradient(rgba(var(--v-theme-primaryContainerLight), 1) 10%, transparent 10%);
}

.gradient-separator-footer {
  background: linear-gradient(transparent 50%, rgba(var(--v-theme-primaryContainerLight), 1) 50%);
}
</style>
