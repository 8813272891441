<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 2000 2000"
  >
    <g
      :fill="color"
      :fill-opacity="opacity"
    >
      <path
        d="m1617.99,1874.71H589.7c-6.55,0-12.83-2.6-17.46-7.23l-302.4-302.4c-29.96-29.96-46.46-69.79-46.46-112.17s16.5-82.2,46.46-112.17l295.17-295.17v-439.45c0-93.72,76.24-169.97,169.96-169.97s169.97,76.25,169.97,169.97v362.24c30.81-31.05,73.5-50.3,120.59-50.3,61.46,0,115.42,32.81,145.27,81.82,29.86-49.02,83.81-81.82,145.28-81.82s115.42,32.81,145.27,81.82c29.85-49.02,83.81-81.82,145.27-81.82,93.72,0,169.97,76.25,169.97,169.97v628.05c0,87.47-71.17,158.63-158.63,158.63Zm-1018.06-49.38h1018.06c60.24,0,109.25-49.01,109.25-109.25v-628.05c0-66.5-54.1-120.59-120.59-120.59s-120.58,54.1-120.58,120.59v80.82c0,13.64-11.05,24.69-24.69,24.69s-24.69-11.05-24.69-24.69v-80.82c0-66.5-54.1-120.59-120.58-120.59s-120.59,54.1-120.59,120.59v80.82c0,13.64-11.05,24.69-24.69,24.69s-24.69-11.05-24.69-24.69v-80.82c0-66.5-54.1-120.59-120.58-120.59s-120.59,54.1-120.59,120.59v80.82c0,13.64-11.05,24.69-24.69,24.69s-24.69-11.05-24.69-24.69v-562.73c0-66.49-54.1-120.59-120.59-120.59s-120.58,54.1-120.58,120.59v764.84c0,13.64-11.05,24.69-24.69,24.69s-24.69-11.05-24.69-24.69v-255.56l-260.26,260.26c-20.63,20.64-32,48.08-32,77.25s11.36,56.61,32,77.25l295.17,295.17Z"
      />
      <path
        d="m1653.5,743.26h-598.88c-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69h598.88c40.66,0,73.74-33.08,73.74-73.74V248.4c0-40.66-33.08-73.73-73.74-73.73H346.5c-40.66,0-73.74,33.08-73.74,73.73v371.75c0,40.66,33.08,73.74,73.74,73.74h68.84c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69h-68.84c-67.89,0-123.12-55.23-123.12-123.12V248.4c0-67.88,55.23-123.11,123.12-123.11h1307c67.89,0,123.12,55.23,123.12,123.11v371.75c0,67.89-55.23,123.12-123.12,123.12Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#4d4d4d'
    },
    opacity: {
      type: Number,
      default: 1
    }
  }
}
</script>
