<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 2000 2000"
  >
    <g
      :fill="color"
      :fill-opacity="opacity"
    >
      <path
        d="m937.07,1400.8H171.77c-13.64,0-24.69-11.05-24.69-24.69v-493.65c0-118.49,96.4-214.89,214.89-214.89h1276.04c105.83,0,195.02,75.69,212.07,179.97,2.21,13.45-6.92,26.15-20.38,28.35-13.48,2.23-26.15-6.93-28.35-20.38-13.14-80.29-81.83-138.56-163.35-138.56H361.98c-91.27,0-165.51,74.25-165.51,165.51v468.96h740.61c13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69Z"
      />
      <path
        d="m448.71,1658.7h-210.44c-50.28,0-91.19-40.9-91.19-91.19v-191.39c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v191.39c0,23.05,18.75,41.81,41.81,41.81h210.44c23.06,0,41.81-18.76,41.81-41.81v-191.39c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v191.39c0,50.29-40.91,91.19-91.19,91.19Z"
      />
      <path
        d="m574.37,1127.63H171.77c-13.64,0-24.69-11.05-24.69-24.69v-197.92c0-13.64,11.05-24.69,24.69-24.69h231.38c108.02,0,195.91,87.89,195.91,195.91v26.71c0,13.64-11.05,24.69-24.69,24.69Zm-377.9-49.38h353.21v-2.02c0-80.8-65.73-146.53-146.53-146.53h-206.69v148.54Z"
      />
      <path
        d="m937.07,1127.63h-147.52c-8.61,0-16.59-4.49-21.08-11.84l-63.23-103.62c-7.1-11.64-3.42-26.83,8.22-33.93,11.65-7.1,26.84-3.42,33.94,8.22l56.01,91.79h133.66c13.64,0,24.69,11.06,24.69,24.7s-11.06,24.69-24.69,24.69Z"
      />
      <path
        d="m1638.03,716.95H361.98c-8.05,0-15.6-3.93-20.22-10.52-4.62-6.6-5.73-15.04-2.98-22.6l189.21-520.49c3.55-9.76,12.82-16.26,23.2-16.26h897.63c10.39,0,19.66,6.5,23.2,16.26l189.21,520.49c2.75,7.57,1.64,16-2.98,22.6-4.62,6.59-12.17,10.52-20.22,10.52Zm-1240.81-49.38h1205.56l-171.26-471.11h-863.04l-171.26,471.11Z"
      />
      <g>
        <path
          d="m361.95,716.96c-11.99,0-22.51-8.75-24.38-20.96-1-6.4-24.94-150-146.72-150-13.64,0-24.69-11.05-24.69-24.69s11.05-24.69,24.69-24.69c131.64,0,185.47,125.55,195.54,191.94,2.04,13.48-7.22,26.07-20.71,28.11-1.25.19-2.5.28-3.73.28Z"
        />
        <path
          d="m1638.06,716.96c-1.23,0-2.48-.09-3.73-.28-13.48-2.04-22.75-14.63-20.71-28.11,10.07-66.39,63.88-191.94,195.53-191.94,13.64,0,24.69,11.05,24.69,24.69s-11.05,24.69-24.69,24.69c-122.55,0-146.48,148.47-146.71,149.97-1.85,12.22-12.38,20.99-24.38,20.99Z"
        />
      </g>
      <path
        d="m1469.83,1852.92c-218.4,0-383.09-74.51-383.09-173.33v-195.65c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v195.65c0,58.63,137.05,123.95,333.71,123.95s333.71-65.32,333.71-123.95v-195.65c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v195.65c0,98.82-164.69,173.33-383.09,173.33Z"
      />
      <path
        d="m1469.83,1661.62c-218.4,0-383.09-74.51-383.09-173.33v-195.65c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v195.65c0,58.63,137.05,123.95,333.71,123.95s333.71-65.32,333.71-123.95v-195.65c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v195.65c0,98.82-164.69,173.33-383.09,173.33Z"
      />
      <path
        d="m1469.83,1470.32c-218.4,0-383.09-74.51-383.09-173.33v-195.65c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v195.65c0,58.63,137.05,123.95,333.71,123.95s333.71-65.32,333.71-123.95v-195.65c0-13.64,11.05-24.69,24.69-24.69s24.69,11.05,24.69,24.69v195.65c0,98.82-164.69,173.33-383.09,173.33Z"
      />
      <path
        d="m1469.83,1275.85c-218.4,0-383.09-74.51-383.09-173.33s164.69-173.34,383.09-173.34,383.09,74.52,383.09,173.34-164.69,173.33-383.09,173.33Zm0-297.29c-196.66,0-333.71,65.33-333.71,123.96s137.05,123.95,333.71,123.95,333.71-65.32,333.71-123.95-137.05-123.96-333.71-123.96Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#4d4d4d'
    },
    opacity: {
      type: Number,
      default: 1
    }
  }
}
</script>
